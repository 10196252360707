//BRAND
export const name = 'Mr Robot Course';
export const company = 'BF DIGITAL';
export const websiteURL = 'https://aicourse.bfdigital.ma';
export const serverURL = 'https://appmrrobot.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/scan-ecom.appspot.com/o/logomrrobotai.png?alt=media&token=6ad7f2b7-5444-43a8-ba72-0bcc413f663f';
export const razorpayEnabled = false;
export const paypalEnabled = true;

//PRICING :-

//FREE
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY
export const MonthType = 'Monthly Plan';
export const MonthCost = 9;
export const MonthTime = 'month';

//YEARLY
export const YearType = 'Yearly Plan';
export const YearCost = 89;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The AI Course Generator revolutionized my content creation process, providing accurate and relevant topics effortlessly. It's a time-saving powerhouse that enhances the quality and relevance of my courses. A must-have tool for educators seeking efficiency and impactful online learning experiences.";
export const from = "Mr Marouane";
export const profession = 'CFO at BF DIGITAL';
export const photoURL = 'https://play-lh.googleusercontent.com/sV_ffBmBJt_je4RZHnfaCfcnL-Hy6C14Iol7H5EMj9fzI2GDOonuojdn5t9p6n9IAX8j';

//PAYPAL
export const paypalPlanIdOne = "P-7NR27632WJ551850SMYWF7DY";
export const paypalPlanIdTwo = "P-9T065222EC293082SMYWGBAY";

// //RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";
